$header-size: 180px;
$header-size-small: 80px;
$tile-color: #fff;
$tile-text-color: #24802e;
$footer-size: 0px; //was 100px;
$main-color: #24802e;

.news-number-counter{
  position: absolute;
    right: 17px;
    top: 11px;
    height: 11px;
    width: 11px;
    background-color: #bc0303;
    opacity: 80%;
    border-radius: 50%;
    display: inline-block;
    color: white;
    font-size: xx-small;
}

.menu-top-title {
  top: 10px;
  position: absolute;
  height: 100% !important;
  padding-top: 0px;
  padding-bottom: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;

}


.menu-conference {
  color: #fff !important;
}

.checking-data {
  color: $main-color !important;
}

.dropdown-menu {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1) !important;
}
.dropdown-menu[data-bs-popper]{
  left:unset;
}

.dropdown-desktop {
  background: $main-color !important;
}

.login-button-text {
  display: inline-block;
  vertical-align: middle;
}

.mobile-update-button {

  position: absolute !important;
  color: $main-color !important;
  text-decoration: none;
  bottom: env(safe-area-inset-bottom) !important;
  right: 5px;
  background: #f7f6f4 !important;
  border: none;


}

.toolbar {
  // padding: 0 !important;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
}


.collapse-notification {
  position: absolute;
  right: 0px;
  font-size: 28px;
  width: 40px;
  cursor: pointer;
}

.offcanvas {
  width: 300px;
}

.toolbar-submenu {
  border-radius: 0px !important;
  width: 101% !important;
  top: 95% !important;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  left: -1px !important;
  z-index: 0 !important;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1) !important;
}

.submenu-link {
  display: inline-block;
  width: 100px;
}

.toolbar-link {
  cursor: pointer;
}

.show-hour {
  margin-top: 23%;
  margin-right: 18%;
  font-size: 30px;
  color: #f7f6f4 !important;
}

.toolbar-triangle {
  position: absolute;
  top: 35px;
  width: 0;
  height: 0;
  padding-bottom: 0px !important;
  margin: 0px;
  margin-left: 42%;

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $main-color;
}

.desktop-submenu {
  --background: $main-color;
  color: #f7f6f4;
  position: absolute;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);


}

.desktop-menu-title {
  padding: 1px;
  margin-left: 40px !important;
  margin-right: 40px !important;
  position: absolute;
}

.banner_mobile {
  width: 100% !important; /* you can use % */
  height: auto;
  margin-top: 20px;
}

.desktop-menu-link {
  padding: 1px;
  margin-left: 40px !important;
  margin-right: 40px !important;
  text-transform: capitalize !important;
}

.dashboard-item-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 50px;
}

.desktop-menu-container {
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center; //center
  margin-left: 120px;
  margin-right: 180px;
  max-width: calc(100% - 250px)
}

.desktop-menu-decoration {
  position: relative;
  color: inherit;
  text-decoration: none !important;
}

.desktop-menu-open::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .5em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .28em solid transparent;
  border-bottom: 0;
  border-left: .28em solid transparent;
}

.desktop-menu-close::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .5em;
  vertical-align: .255em;
  content: "";
  border-bottom: .3em solid;
  border-right: .28em solid transparent;
  border-top: 0;
  border-left: .28em solid transparent;
}

.desktop-menu-user {
  margin-left: 40px !important;
  margin-right: 40px !important;

  position: absolute;
}

.desktop-user-button {
  position: relative;
  height: 40px;
  min-width: 200px;
  max-width: 200px;
  background: #f7f6f4;
  color: $main-color;


  border: 1px solid $main-color;
  border-radius: 25px;
  font-size: 18px;
}


.desktop-user-button:hover {
  background-color: $main-color;
  color: #f7f6f4;
  border-color: $main-color;
}

.desktop-user-button:active {
  background-color: $main-color !important;
  color: #f7f6f4;
  border-color: $main-color;
}

.dropdown-toggle {
  color: rgba(0, 0, 0, 0.90) !important;
}


/*.dropdown-button:hover {
  color: #fff;
  background-color: $main-color;
  border-color: #ffffff;
  cursor: pointer;
}*/

.custom-dropdown-item {
  background: #fff;
  color: #000 !important;
}

@media (min-width: 992px) {


  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background: transparent;

    .account-dropdown {
      top: 0px;
      position: relative !important;
    }
  }
  .offcanvas-end {
    display: none;
  }


  .mobile-deactivated {
    display: inline-block !important;
  }
  .mobile-activated {
    display: none !important;
  }

}

.update-button {
  border: none;

  .spinner-border {
    height: 30px;
    width: 30px;
  }
}

.canvas-profile-img {
  top: 0px;
  background-size: cover;
  width: 100%;
  text-align: center;
  height: 130px;
}

.canvas-profile-image {
  background: lightgrey;
  display: block;
  border-radius: 100px;
  border: 1px solid #fff;
  width: 108px;
  height: 108px;
  margin: 0px auto 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
}

.offcanvas-start {
  padding: 0px;
  top: env(safe-area-inset-top);

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.btn-light:focus {
  box-shadow: none;
}

.offcanvas-end {
  padding: 0px;
  top: env(safe-area-inset-top);

  .btn-close {
    position: absolute;
    top: 12px;
    left: 5px;
    display: inline-block;
  }

  .custom-offcanvas-body {
    margin-top: 10px;
    padding: 0;
    overflow-x: hidden !important;

  }

  .offcanvas-title {
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 25%;
    font-size: 1.4rem;
  }
}


.profile-button:hover {
  color: #fff;
  background-color: $main-color;
  border-color: $main-color;
}


.profile-button-mobile {

  margin-top: 10px;
  margin-bottom: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  background: #f7f6f4;
  color: $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $main-color;
  padding: 5px;
  border-radius: 25px;
  z-index: 11;

  padding: 8px;

  font-size: 18px;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.logout-button-mobile {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 55px;
  height: 55px;
  background: #f7f6f4;
  color: $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $main-color;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 300px;
  z-index: 11;
  padding: 8px;
  font-size: 18px;
}

.logout-button-mobile:hover {
  background-color: $main-color;
  color: #f7f6f4;
  border-color: $main-color !important;
}

.profile-button-mobile:hover {
  background-color: $main-color;
  color: #f7f6f4;
  border-color: $main-color !important;
}


.canvas-profile {
  padding-top: 10px;
  padding-bottom: 10px;
  width: fit-content;
  text-align: center;
  width: 100%;
  height: 180px;
  min-height: 180px;
  background: $main-color;//url('assets/bg.jpg') no-repeat fixed;
  margin-bottom: 20px;
}

.mobile-deactivated {
  display: none;
}

.mobile-activated {
  display: inline-block;
}

.custom-dropdown {
  background-color: #f7f6f4;

  border: 1px solid $main-color;
  border-radius: 25px;
  color: $main-color;
  cursor: pointer;
  font-size: 1rem;
  list-style: none;
  text-align: left;
}

.custom-nav-link {
  text-align: center;
  color: $main-color !important;
  width: 200px;
}

.navbar {
  height: 50px;
}

/*.dropdown-item {
  color: #212529;
  padding: .25rem 1.5rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-decoration: none;
}
*/
@function set-color($color) {
  @if (lightness($color) > 40) {
    @return #000;
  } @else {
    @return #FFF;
  }
}

.dropdown-item {
  cursor: pointer;
  color: set-color($main-color);
}

.dropdown-item:hover {
  cursor: pointer;
  color: $main-color !important;
}


#dashboard {

  .dashboard-content {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: $main-color; //url('assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}


.header {

  background: #fff;
  .nav-area {
    height: 40px;
    background: #444; //#004cff;
    .nav-icon {
      position: absolute;
      top: 0px;
      left: 10px;
      width: 40px;
      height: 40px;
      font-size: 2.6rem;
      color: #f7f6f4;
    }

    .live-clock {
      position: absolute;
      font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
      top: 10px;
      right: 100px; //was 10px
      color: #f7f6f4;
      font-size: 1.2rem;
      z-index: 2;
    }
  }

  height: $header-size;


  grid-area: 1/1/2/2;
  margin-top: 0px;
  text-align: center;
  width: 100%;
  background: $main-color;//url('assets/bg.jpg') no-repeat fixed;
  background-size: 100%;

}

.header-text {
  grid-area: 2/1/3/2;
  background-color: #F1E6A1; //#775c50;
  //box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 8px rgb(0 0 0 / 10%) !important;
}

.footer {
  height: $footer-size;
  grid-area: 4/1/5/2;
  background: #fff;
  z-index: 2;
  box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.1);

  h4 {
    color: $main-color;
    margin: 5px 0 0 20px;
  }
}

.dashboard-content {
  overflow: auto;
  grid-area: 3/1/4/2;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
  /*&::-webkit-scrollbar {
    display: none;
  }*/
  overflow-y: visible;

  .menu-wrapper {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    padding-top: 15px;
    justify-content: flex-start;

    .tile-item {
      cursor: pointer;
      background-color: $tile-color;
      flex: 0 1 30%;
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      min-width: 140px;
      min-height: 70px;
      //width: calc(33.333% - 20px);
      max-height: 70px;
      width: 70px;
      max-width: 140px;
      border-radius: 15px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 35px;
      text-align: center;
      line-height: 0.9rem;
      color: $main-color;
      text-decoration: none;
      box-shadow: 0px 5px 8px rgb(0 0 0 / 10%);

      .icon {
        flex: 0 1 100%;
        font-size: 2.6em;
        width: 60px;
        padding-top: 15px;
      }

      .text {
        flex: 0 1 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
        overflow-y: visible;
        width: 75px;
        margin-top: 20px;
        color: #24802e;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .icon-sponsor, .icon-aussteller {
        font-family: "documedias-icons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 70px;
      }

      .icon-sponsor:before {
        content: "\e904";
      }

      .icon-aussteller:before {
        content: "\e903";
      }

      .icon-aussteller-new {
        width: 70px;
        background-size: 35px 35px;
      }
    }
  }

}


.header-content {
  background: #fff;
  height: calc(#{$header-size} - 0px);
  position: relative;

  img {
    position: absolute;
    left:15px;
    height: calc(#{$header-size} );
  }

  .tag {
    position: absolute;
    top: 60px;
    right: 300px;
    color: #444444;
    height: 30px;
    font-size: 1.8rem;
    font-weight: bold;

  }

  .shape {
    z-index: 10;
    position: absolute;
    top: -50px;
    right: -40px;
    height: 315px;
    width: 320px;
    background-color: $brand-color;

    mask-image: url('../public/assets/wcpt-edge.svg');
    background-repeat: no-repeat;
    background-size: cover;

    .date {
      position: absolute;
      top: 120px;
      left: -10px;
      font-size: 2.4rem;
      font-weight: bold;

      color: #f7f6f4;
      height: 40px;
      width: 100%;
    }

    .register {
      position: absolute;
      top: 170px;
      left: 60px;
      font-size: 1.2rem;
      background: $main-color;
      color: #a7c7ff;
      border-radius: 50px;
      height: 40px;
      width: 200px;
      padding-top: 10px;
    }

    .register:hover {
      cursor: pointer;
    }

    .help-btn {
      position: absolute;
      top: 220px;
      left: 60px;
      font-size: 1.2rem;
      background: $main-color;
      color: #a7c7ff;
      border-radius: 50px;
      height: 40px;
      width: 200px;
      padding-top: 10px;
    }
  }
}


.header-text {
  font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px 0 20px;
  text-align: center;
  color: #275959;
  background: #F1E6A1;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

  .title {
    font-size: 1.6rem
  }

  .addition {
    padding-top: 10px;
  }
}

#dashboard {
  display: grid;
  grid-template-rows: $header-size 120px 1fr $footer-size;
  grid-template-columns: 1fr;


  .header {

    grid-area: 1/1/2/2;
    max-height: $header-size;
    font-family: 'Raleway,'"Helvetica Neue", Helvetica, Arial, sans-serif;

    .nav-area {
      height: 40px;
      background: #444; //$main-color;
      .nav-icon {
        position: absolute;
        top: 0px;
        left: 10px;
        width: 40px;
        height: 40px;
        font-size: 2.6rem;
        color: #f7f6f4;
      }

      .live-clock {
        position: absolute;
        font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
        top: 10px;
        right: 100px; //was 10px
        color: #f7f6f4;
        font-size: 1.2rem;
        z-index: 2;
      }
    }

  }

  .dashboard-content {
    grid-area: 3/1/4/2;
    margin: 0px;
    padding: 25px;

    .menu-wrapper {
      max-width: 1500px;
      margin: 0 auto;
      justify-content: center;

      .tile-item {
        background-color: $tile-color;
        font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
        position: relative;
        flex: 0 1 220px;
        width: 220px;
        height: 160px;
        max-width: 220px;
        max-height: 160px;
        border-radius: 15px;

        .text {
          position: absolute;
          top: 5px;
          left: 10px;
          right:5px;
          width: 200px;
          text-align: left;
          font-size: 1.2rem;
          margin-top: 5px;
          line-height: 1.4rem
        }

        .icon {
          position: absolute;
          bottom: 5px;
          right: 10px;
          padding-top: 45px;
          font-size: 3.5rem;
        }

        .icon-sponsor, .icon-aussteller {
          position: absolute;
          bottom: 0px;
          right: 10px;
          width: 60px;

          font-size: 3.3rem !important;
        }

        .icon-aussteller-new {
          position: absolute;
          bottom: 0px;
          right: 10px;
          width: 60px;
          background-size: 60px 60px !important;
        }
      }
    }
    .video-wrapper {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      padding-top: 0px;
      max-width: 1100px;
      margin: 0 auto 50px auto;
      justify-content: center;

      .video-container {
        width: 300px;
        margin:20px;


        .video-item {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
        }
      }
    }


  }

  .search-footer {
    width: 100%;
    height: 80px;
    //grid-area: 4/1/5/2;

    .input-group {
      position: relative;
      height: 120px;

      .search-input {
        display: inline-block;
        width: 70%;
        height: 50px;
        border: none;
        border-radius: 50px;
        margin-left: 10%;
        margin-right: 10px;
        padding-left: 20px;
      }

      .buttons {
        display: inline-block;

        .search-btn {
          display: block;
          width: 200px;
          height: 50px;
          border-radius: 50px;
          font-size: 1.4rem;
          padding-top: 12px;
          text-align: center;
          color: #f7f6f4;
          background: $main-color;
          text-decoration: none;
        }

        .help-btn {
          position: absolute;
          bottom: 0;
          display: block;
          width: 200px;
          height: 50px;
          border-radius: 50px;
          font-size: 1.4rem;
          padding-top: 12px;
          text-align: center;
          color: $main-color;
          background: #d2d755;
        }
      }
    }


    .text {
      font-size: 1.3rem;
      display: block;
      margin: 40px 50px 0 0px;
      font-weight: bold;
      position: absolute;
      bottom: 18px;
      right: 55px;
      color: #fff;
    }

    .logo {

      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 100px;
      height: 35px;
      position: absolute;
      bottom: 10px;

      right: 0px;
    }
  }
}


#dashboard-sub {
  //background-color: rgba(63, 91, 146, 0.3);
  .dashboard-content {
    overflow: auto;
    margin-top: 45px;
    margin-right: 10px;
    margin-left: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    .menu-wrapper {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      padding-top: 15px;
      justify-content: flex-start;

      .tile-item {
        background-color: $tile-color;
        flex: 0 1 30%;
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        min-width: 140px;
        min-height: 45px;
        //width: calc(33.333% - 20px);
        max-height: 70px;
        width: 70px;
        max-width: 140px;
        border-radius: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 35px;
        text-align: center;
        line-height: 0.9rem;
        color: $main-color;
        text-decoration: none;

        .icon {
          flex: 0 1 100%;
          font-size: 2.6em;
          width: 60px;
          padding-top: 15px;
        }

        .text {
          display:block;
          flex: 0 1 100%;
          font-size: 0.75rem;
          line-height: 0.75rem;
          overflow-y: visible;
          width: 100px;
          margin-top: 20px;
          color: #24802e;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .icon-sponsor, .icon-aussteller {
          font-family: "documedias-icons";
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          width: 70px;
        }

        .icon-sponsor:before {
          content: "\e904";
        }

        .icon-aussteller:before {
          content: "\e903";
        }

        .icon-aussteller-new {
          width: 70px;
          background-size: 35px 35px;
        }
      }
    }

  }

  .dashboard-content {
    .menu-wrapper {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      padding-top: 15px;
      max-width: 1500px;
      margin: 0 auto;
      justify-content: center;

      .tile-item {
        background-color: $tile-color;
        font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
        position: relative;
        flex: 0 1 220px;
        width: 220px;
        height: 160px;
        max-width: 220px;
        max-height: 160px;

        .text {
          position: absolute;
          top: 5px;
          left: 10px;
          width: 200px;
          text-align: left;
          font-size: 1.3rem;
          margin-top: 5px;
          line-height: 1.4rem
        }

        .icon {
          position: absolute;
          bottom: 5px;
          right: 10px;
          padding-top: 45px;
          font-size: 3.5rem;
        }

        .icon-sponsor, .icon-aussteller {
          position: absolute;
          bottom: 0px;
          right: 10px;
          width: 60px;

          font-size: 3.3rem !important;
        }

        .icon-aussteller-new {
          position: absolute;
          bottom: 0px;
          right: 10px;
          width: 60px;
          background-size: 60px 60px !important;
        }
      }
    }
  }

  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background: url(../img/shadow.png) no-repeat bottom center;

    background-color: #f7f6f4;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.3;

  }

  .title {
    background-color: #9EBF53 !important;
    color: #fff;
  }

  .icon-sponsor, .icon-aussteller {
    font-family: "documedias-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem !important;
  }

  .icon-sponsor:before {
    content: "\e904";
  }

  .icon-aussteller:before {
    content: "\e903";
  }

  .badge {
    top: 13px;
  }

  .item {
    background-color: transparent;
  }

  .item-icon-left {
    #f7f6f4-space: normal;
    position: relative;

    &.active {
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 4px;
        left: 0;
        top: 0;
      }
    }

    &.activated {
      border-color: transparent;
    }
  }

  .item-icon-left i + span {
    font-size: 0.9rem;
    font-weight: 400;
    vertical-align: middle;
  }

  .item-divider.item-icon-left {
    padding-left: 16px;
  }

  .item-icon-left ~ .item-divider {
    margin-top: 20px;
  }
}

.brand-background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.3;

}


.corner-item-mobile {
  position: fixed;
  display: flex;
  left: 0;
  top: 0px;
  z-index: 200;
  background-color: #f5f5f5;
  border-right: solid 1px rgba(0, 0, 0, 0.125);
  border-bottom: solid 2px rgba(0, 0, 0, 0.125);
  border-top: solid 2px rgba(0, 0, 0, 0.125);
  justify-content: center;
  align-items: center;

  i {
    position: absolute;
    height: 60px;
    width: 100%;
    margin: 0 auto;
    font-size: 3rem;
    opacity: 0.2;
    line-height: 69px;
    text-align: center;
    z-index: -1;
  }

  .corner-item-text {
    font-size: 0.7rem;
    font-weight: 700;
  }
}

@media (max-width: 768px) {

  .offcanvas-end {
    padding: 40px 0 0 0;
    top: env(safe-area-inset-top);

    .btn-close {
      position: absolute;
      top: 52px;
      left: 5px;
      display: inline-block;
      color:black;
    }
  }

  .offcanvas-start {
    padding: 50px 0 0 0 ;
    top: env(safe-area-inset-top);

    .btn-close {
      position: absolute;
      top: 55px;
      right: 5px;
    }
  }

  .custom-offcanvas-body {
    overflow-x: hidden !important;
    margin-bottom: 40px;
  }
  .dashboard-item-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 35px;
  }

  .canvas-item-icon {
    position: relative;
    right: 10px;
    font-size: 20px;
  }

  .update-button {
    position: absolute !important;
    color: $main-color !important;
    text-decoration: none;
    top: 12px !important;
  }


  .corner-item-mobile {
    position: fixed;
    display: flex;
    left: 0;
    top: 0px;
    z-index: 200;
    background-color: #f5f5f5;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    justify-content: center;
    align-items: center;

    i {
      position: absolute;
      height: 60px;
      width: 100%;
      margin: 0 auto;
      font-size: 3rem;
      opacity: 0.2;
      line-height: 69px;
      text-align: center;
      z-index: -1;
    }

    .corner-item-text {
      font-size: 0.7rem;
      font-weight: 700;
    }
  }

  .dashboard-content {
    background: $main-color;// url('assets/bg.jpg');
    background-size: cover !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  #dashboard {
    display: grid;
    grid-template-rows: $header-size-small 150px 1fr $footer-size;
    grid-template-columns: 1fr;

    .background {
      background-size: cover;
      background-position: right;
    }

    .header {
      background: #fff;

      grid-area: 1/1/2/2;
      max-height: 120px;
      font-family: 'Raleway,'"Helvetica Neue", Helvetica, Arial, sans-serif;

      .nav-area {
        height: 40px;
        background: #444; //$main-color;
        .nav-icon {
          position: absolute;
          top: 0px;
          left: 10px;
          width: 40px;
          height: 40px;
          font-size: 2.6rem;
          color: #f7f6f4;
        }

        .live-clock {
          position: absolute;
          font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
          top: 10px;
          right: 10px; //was 10px
          color: #f7f6f4;
          font-size: 1.2rem;
          z-index: 2;
        }
      }

      .header-content {
        height: 120px;
        position: relative;

        img {
          position: absolute;
          left: 0px;
          width:100%;
          height: 120px;
          object-fit: contain;

        }

        .tag {
          position: absolute;
          top: 60px;
          right: 120px;
          color: #f7f6f4;
          height: 30px;
          display: none;
          font-size: 1.0rem;
          font-weight: bold;

        }

        .shape {
          z-index: 10;
          position: absolute;
          top: -50px;
          right: -40px;
          height: 198px;
          width: 200px;
          background-image: url('/assets/wcpt-edge.svg');
          background-repeat: no-repeat;
          background-size: cover;

          .date {
            position: absolute;
            top: 90px;
            left: -10px;
            font-size: 1.0rem;
            font-weight: bold;

            color: #f7f6f4;
            height: 40px;
            width: 100%;
          }

          .register {
            position: absolute;
            top: 120px;
            left: 35px;
            font-size: 0.9rem;
            background: $main-color;
            color: #a7c7ff;
            border-radius: 50px;
            height: 40px;
            width: 120px;
            padding-top: 10px;
          }

          .register:hover {
            cursor: pointer;
          }

          .help-btn {
            position: absolute;
            top: 220px;
            left: 60px;
            font-size: 1.2rem;
            background: $main-color;
            color: #a7c7ff;
            border-radius: 50px;
            height: 40px;
            width: 200px;
            padding-top: 10px;
          }
        }
      }


    }

    .header-text {
      font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
      padding: 20px 0 20px;
      text-align: center;
      color: #275959;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

      .title {
        font-size: 1.2rem;
      }

      .addition {
        font-size: 0.9rem;
        padding-top: 0px;
      }
    }

    .dashboard-content {
      grid-area: 3/1/4/2;
      overflow: scroll;
      margin: 0px;
      padding: 25px;

      .menu-wrapper {
        overflow: scroll;
        max-width: 100%;
        margin: 0 auto;
        justify-content: center;

        .tile-item {
          background-color: $tile-color;
          font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
          position: relative;
          flex: 0 1 120px;
          width: 120px;
          height: 100px;
          max-width: 120px;
          max-height: 100px;

          .text {
            position: absolute;
            top: 5px;
            left: 10px;
            width: 120px;
            text-align: left;
            font-size: 0.9rem;
            margin-top: 5px;
            line-height: 1.0rem
          }

          .icon {
            position: absolute;
            bottom: 5px;
            right: 0px;
            padding-top: 45px;
            font-size: 2.0rem;
          }

          .icon-sponsor, .icon-aussteller {
            position: absolute;
            bottom: 10px;
            right: 0px;
            width: 50px;

            font-size: 2.0rem !important;
          }

          .icon-aussteller-new {
            position: absolute;
            bottom: 10px;
            right: 0px;
            width: 50px;
            font-size: 2.0rem !important;
            background-size: 60px 60px !important;
          }
        }
      }

    }

    .search-footer {
      width: 100%;
      height: 80px;
      //grid-area: 4/1/5/2;

      .input-group {
        position: relative;
        height: 120px;

        .search-input {
          display: inline-block;
          width: 70%;
          height: 50px;
          border: none;
          border-radius: 50px;
          margin-left: 10%;
          margin-right: 10px;
          padding-left: 20px;
        }

        .buttons {
          display: inline-block;

          .search-btn {
            display: block;
            width: 200px;
            height: 50px;
            border-radius: 50px;
            font-size: 1.4rem;
            padding-top: 12px;
            text-align: center;
            color: #f7f6f4;
            background: $main-color;
            text-decoration: none;
          }

          .help-btn {
            position: absolute;
            bottom: 0;
            display: block;
            width: 200px;
            height: 50px;
            border-radius: 50px;
            font-size: 1.4rem;
            padding-top: 12px;
            text-align: center;
            color: #a7c7ff;
            background: $main-color;
          }
        }
      }


      .text {
        font-size: 1.3rem;
        display: block;
        margin: 40px 50px 0 0px;
        font-weight: bold;
        position: absolute;
        bottom: 18px;
        right: 55px;
        color: #fff;
      }

      .logo {

        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 100px;
        height: 35px;
        position: absolute;
        bottom: 10px;

        right: 0px;
      }
    }
  }


  #dashboard-sub {
    //background-color: rgba(63, 91, 146, 0.3);
    .dashboard-content {
      overflow: auto;
      margin-top: 45px;
      margin-right: 10px;
      margin-left: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .menu-wrapper {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        padding-top: 15px;
        justify-content: flex-start;

        .tile-item {
          background-color: $tile-color;
          flex: 0 1 30%;
          display: flex;
          flex-direction: row;
          flex-flow: row wrap;
          min-width: 120px;
          min-height: 45px;
          //width: calc(33.333% - 20px);
          max-height: 70px;
          width: 70px;
          max-width: 140px;
          border-radius: 10px;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 35px;
          text-align: center;
          line-height: 0.9rem;
          color: $main-color;
          text-decoration: none;

          .icon {
            flex: 0 1 100%;
            font-size: 2.6em;
            width: 60px;
            padding-top: 15px;
          }

          .text {
            flex: 0 1 100%;
            font-size: 0.75rem;
            line-height: 0.75rem;
            overflow-y: visible;
            width: 75px;
            margin-top: 20px;
            color: #24802e;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .icon-sponsor, .icon-aussteller {
            font-family: "documedias-icons";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            width: 70px;
          }

          .icon-sponsor:before {
            content: "\e904";
          }

          .icon-aussteller:before {
            content: "\e903";
          }

          .icon-aussteller-new {
            width: 70px;
            background-size: 35px 35px;
          }
        }
      }

    }

    .dashboard-content {
      overflow: scroll;

      .menu-wrapper {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        padding-top: 0px;
        max-width: 100%;
        margin: 0 auto;
        justify-content: center;

        .tile-item {
          background-color: $tile-color;
          font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
          position: relative;
          flex: 0 1 140px;
          width: 140px;
          height: 100px;
          max-width: 140px;
          max-height: 100px;

          .text {
            position: absolute;
            top: 5px;
            left: 10px;
            width: 140px;
            text-align: left;
            font-size: 0.8rem;
            margin-top: 5px;
            line-height: 1.0rem;
            white-space: normal;
          }

          .icon {
            position: absolute;
            bottom: 5px;
            right: 0px;
            padding-top: 45px;
            font-size: 2.5rem;
          }

          .icon-sponsor, .icon-aussteller {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 60px;
            font-size: 2.5rem !important;
          }

          .icon-aussteller-new {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 60px;
            background-size: 60px 60px !important;
          }
        }
      }
    }

    .background {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      //background: url(../img/shadow.png) no-repeat bottom center;

      background-color: #ffffff;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 0.3;

    }

    .title {
      background-color: #9EBF53 !important;
      color: #fff;
    }

    .icon-sponsor, .icon-aussteller {
      font-family: "documedias-icons";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1.4rem !important;
    }

    .icon-sponsor:before {
      content: "\e904";
    }

    .icon-aussteller:before {
      content: "\e903";
    }

    .badge {
      top: 13px;
    }

    .item {
      background-color: transparent;
    }

    .item-icon-left {
      #f7f6f4-space: normal;
      position: relative;

      &.active {
        &:before {
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          width: 4px;
          left: 0;
          top: 0;
        }
      }

      &.activated {
        border-color: transparent;
      }
    }

    .item-icon-left i + span {
      font-size: 0.9rem;
      font-weight: 400;
      vertical-align: middle;
    }

    .item-divider.item-icon-left {
      padding-left: 16px;
    }

    .item-icon-left ~ .item-divider {
      margin-top: 20px;
    }
  }
}
