//$conf-color: #3f6a88;
$conf-color: #24802e;
$primary-color: #FFFFFF;
$primary-font: #FFFFFF;

$alt-color: #FFFFFF;
$alt-font: #000000;

$light: #fff !default;
$stable: #f8f8f8 !default;
$positive: #3C88B3 !default;
$calm: #337ab7 !default;
$balanced: #5bc0de !default;
$energized: #f0ad4e !default;
$assertive: #d9534f !default;
$royal: #8a6de9 !default;
$dark: #FFFFFF !default;

$branding-dark: #1879ca;
$branding-light: #FFFFFF;

[class^="fac-"], [class*=" fac-"] {
  /* use !important to prewvent issues with browser extensions that change fonts */
  font-family: 'documedias-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ion-segment{

  --background: #fff;
}

ion-segment-button {
  --background: #fff;
  --color: #8d8f74;
  --color-checked: #8d8f74 ;
  --background-checked: #fff;
}


.menu-open-button{
  color:#3d5a59 !important;
}
.update-button{
  color:#3d5a59 !important;
  text-decoration: none; 
}
.banner_images{
  position: relative;
  top: 0;
  left: 0;
}
.menu_banner{
  width:100% !important; /* you can use % */
    height: 150px;
    position: relative;
    top: 0;
    left: 0;
}
.menu_logo{
  width:100%; /* you can use % */
  height: auto;
  position: absolute;
  z-index: 20;
  position: absolute;
  top: 25%;
  left: 30px;
}

.fac-exhibitor:before {
  content: "\e903";
}

.fac-sponsor:before {
  content: "\e904";
}

.fac-heimdall:before {
  content: "\e902";
}

.fac-documedias:before {
  content: "\e901";
}

.fac-orcid:before {
  content: "\e900";
}

html, body {
  font-size: 16px;

  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
  //padding:env(safe-area-inset-top, 18px) 0px 40px 0px ;
/*env(safe-area-inset-right, 1em)
env(safe-area-inset-bottom, 40px)
env(safe-area-inset-left, 1.4rem);*/
}

body, .ionic-body, input, button, select, textarea {
  font-family: 'Fira Sans', "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
}

.item {
  white-space: normal;
}

a.item, .item, .item span, .item h1, .item h2, .item h3, .item h4, .item h5, .item h6, .item p, .item-content, .item-content h1, .item-content h2, .item-content h3, .item-content h4, .item-content h5, .item-content h6, .item-content p {
  white-space: normal;
  color: #222;
}

.bar.bar-header {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0px;
}

#menu {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .menu-header-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .list {
    margin-top: -4px;
  }
}


#menu-footer {
  display: flex;
  padding: 0;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 2px rgba(0, 0, 0, 0.24);
  z-index: 99;
  border: none;
  background-color: $primary-color;

  .menu-button-left, .menu-button-right {
    width: 78px;
    font-size: 2rem;
    text-align: center;
    line-height: 48px;
    color: #fff;
  }

  h1 {
    width: 100%;
    line-height: 44px;
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0;
    text-align: center;
  }
}

#navigation {
  .navigation-header-image {
    width: 100%;
    height: auto;
  }

  .icon-sponsor, .icon-aussteller {
    font-family: "documedias-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem !important;
  }

  .icon-sponsor:before {
    content: "\e904";
  }

  .icon-aussteller:before {
    content: "\e903";
  }

  .badge {
    top: 13px;
  }

  .item {
    background-color: transparent;
    border-color: transparent;
  }

  .item-icon-left {
    white-space: normal;
    position: relative;

    &.active {
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 4px;
        left: 0;
        top: 0;
      }
    }

    &.activated {
      border-color: transparent;
    }
  }

  .item-icon-left i + span {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .item-divider {
    //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #ddd;
  }

  .item-divider.item-icon-left {
    padding-left: 16px;
  }

  .item-icon-left ~ .item-divider {
    margin-top: 20px;
  }
}

#branding-wrapper {
  color: #fff;
  background: url('assets/bg.jpg') no-repeat center center fixed;
  background-color: $conf-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background: -moz-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333F6A88', endColorstr='#336D8CA2', GradientType=1); /* IE6-9 fallback on horizontal gradient */

  .scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .branding-intro {
    width: 100%;
    max-width: 600px;
    max-height: 30px;
  }

  #branding-intro-logo-image path {

  }

  #branding-intro-logo-text path {
    stroke: #fff;
    stroke-dasharray: 2200;
    stroke-dashoffset: 1000;
    animation: dash-text 2s ease-out forwards 1;
    transition: fill .2s ease-out;
  }

  .button-branding {
    background: transparent;
    color: #fff;
    display: block;
    margin: 0 auto;
    border: solid 1px #fff;
    border-radius: 20px;
    text-transform: uppercase;
    transition: background-color ease-in .2s;
    min-width: 200px;

    &:hover, &:active, &:focus {
      background-color: rgba(#fff, 0.4);
    }
  }

  .input-branding-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 194px;
    white-space: nowrap;

    i {
      font-size: 2rem;
      opacity: 0;
      visibility: hidden;
      transform: translateX(0);
      transition: all ease-in .2s;

      &.toggled {
        transform: translateX(20px);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .input-branding {
    float: left;
    background-color: rgba(255, 255, 255, 0.25);
    color: #fff;
    display: inline-block;
    margin: 0 auto;
    border: none;
    text-transform: uppercase;
    transition: all ease-in .2s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    padding: 6px 12px;
    line-height: 30px;
    font-size: 0.9rem;
    text-align: center;
    min-width: 200px;

    &.toggled {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &.icon-toggled {
      transform: translateX(-14px);
    }

    &::-webkit-input-placeholder {
      color: rgba(#fff, 0.75);
    }

    &:-moz-placeholder {
      color: rgba(#fff, 0.75);
    }

    &::-moz-placeholder {
      color: rgba(#fff, 0.75);
    }

    &:-ms-input-placeholder {
      color: rgba(#fff, 0.75);
    }
  }

  .subtext {
    text-align: center;
    white-space: normal;
    margin: 10px;
  }
}

@keyframes dash-logo {
  0% {
    stroke-dashoffset: 2000;
    fill: none;
  }
  50% {
    stroke-dashoffset: 0;
    fill: rgba(255, 255, 255, 0);
    stroke: rgba(255, 255, 255, 1);
  }
  100% {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(255, 255, 255, 0);
  }
}

@keyframes dash-text {
  0% {
    stroke-dashoffset: 1000;
  }
  50% {
    stroke-dashoffset: 0;
    fill: rgba(255, 255, 255, 0);
    stroke: rgba(255, 255, 255, 1);
  }
  100% {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(255, 255, 255, 0);
  }
}

#conferenceSwitch {
  background: url('assets/bg.jpg') no-repeat center center fixed;
  background-color: $conf-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background: -moz-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333F6A88', endColorstr='#336D8CA2', GradientType=1); /* IE6-9 fallback on horizontal gradient */

  .conf-header {
    color: #fff;
    height: 70px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: absolute;
    width: 100%;
    z-index: 100;

    .help-button {
      font-size: 34px;
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .imprint-button {
      font-size: 34px;
      position: absolute;
      top: 15px;
      left: 15px;
    }

    .branding-intro {
      width: 100%;
      max-width: 300px;
      max-height: 18px;
      margin-top: 22px;
    }

    .branding-intro-logo-image path {
      stroke: #fff;
      stroke-dasharray: 2000;
      stroke-dashoffset: 2000;
      animation: dash-logo 2s ease-out forwards 1;
      transition: fill .2s ease-out;
    }

    .branding-intro-logo-text path {
      stroke: #fff;
      stroke-dasharray: 2200;
      stroke-dashoffset: 1000;
      animation: dash-text 2s ease-out forwards 1;
      transition: fill .2s ease-out;
    }
  }

  .conference-switch-wrapper {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .conference-switch-item {
    position: relative;
    height: 60px;
    width: 100%;

    &.manage-toggled {
      .conference-content {
        img {
          transform: translateX(50px);
        }

        h2 {
          transform: translateX(50px);
        }
      }

      .remove-congress-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .conference-switch-item.control-item {
    display: inline-block;
    width: 50%;
    float: left;

    .conference-content {
      padding: 10px;
      text-align: center;

      .add-congress-icon {
        position: static;
        display: inline-block;
      }

      h2 {
        display: inline-block;
      }
    }
  }

  .conference-switch-item.grid-item {
    //width: 50vw;
    height: 50vw;
    border: none;
    display: inline-block;
    margin-bottom: -5px;

    .conference-shadow {
      height: 100%;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(-90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.2) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.2) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33000000', endColorstr='#33ffffff', GradientType=1); /* IE6-9 fallback on horizontal gradient */
      }
    }

    .conference-content {
      display: flex;
      height: 100%;
      padding: 10px;
      justify-content: center;
      align-items: center;

    }

    .conference-content h2 {
      margin-top: 5px;
      font-size: 0.9em;
      line-height: 1.42em;
    }

    .conference-content p {
      font-size: 0.8em;
      line-height: 1.42em;
    }

    img {
      position: static;
      top: auto;
      left: auto;
      display: block;
      margin: 0 auto;
      max-height: 60px;
      max-width: 60px;
    }

    .remove-congress-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      left: auto;
    }

    &.manage-toggled {
      .conference-content {
        img {
          transform: translateX(0);
        }

        h2 {
          transform: translateX(0);
        }
      }

      .remove-congress-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .conference-shadow {
    transition: all ease-in 0.2s;
    width: 100%;
    height: 60px;
    position: absolute;
    z-index: 1;
    top: 0;
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.2) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.2) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33000000', endColorstr='#33ffffff', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  }

  .conference-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 60px;
    top: 0;
    padding: 10px 10px 10px 60px;
    transition: all ease-in 0.2s;
    will-change: transform;
    transform: translateX(0);

    img {
      transition: all ease-in 0.2s;
      will-change: transform;
      transform: translateX(0);
      position: absolute;
      top: 10px;
      left: 10px;
      max-width: 40px;
      max-height: 40px;
      width: 100%;
      height: 100%;
      border-radius: 20%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .add-congress-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      max-width: 40px;
      max-height: 40px;
      width: 100%;
      height: 100%;
      border-radius: 20%;
      background-color: #f0ad4e; //#5cb85c;
      text-align: center;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      i {
        color: #fff;
        line-height: 40px;
      }
    }

    .remove-congress-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      max-width: 40px;
      max-height: 40px;
      width: 100%;
      height: 100%;
      border-radius: 20%;
      background-color: #d9534f;
      text-align: center;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      opacity: 0;
      visibility: hidden;
      transition: all ease-in 0.2s;
      will-change: transform;

      i {
        color: #fff;
        line-height: 40px;
      }
    }

    h2 {
      margin: 0;
      font-size: 1rem;
      line-height: 40px;
      transition: all ease-in 0.2s;
      will-change: transform;
      transform: translateX(0);
      color: #fff;
    }
  }
}

.branding-bar {
  background: url('assets/bg.jpg') no-repeat top right scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  //background: -moz-linear-gradient(180deg, $conf-color 0%,  $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* FF3.6-15 */
  //background: -webkit-linear-gradient(180deg, $conf-color 0%,  $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  //background: linear-gradient(180deg, $conf-color 0%,  $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333F6A88', endColorstr='#336D8CA2', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  &.bar.bar-header h1 {
    color: #fff;
  }
}

*::-webkit-input-placeholder {
  display: block;
  padding-top: 4px;
}

.loading-container-small {
  z-index: 99;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 45px;
  width: 100% !important;
}

.progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90vw;
  border-radius: 4px;

  .progress {
    height: 40px;
    border-radius: 4px;
    width: 100%;
    overflow: visible;
    background: #fff;
    box-shadow: 0 10px 20px rgba(68, 68, 68, 0.05);
  }

  .progress-percent {
    float: right;
    font-weight: 500;
    color: #222;
    font-size: 11px;
    position: absolute;
    right: 5vw;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.3);
    padding: 0 5px;
  }

  .progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    background-color: #5bc0de;
    position: relative;
    text-align: left;
    border-radius: 4px;
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-image: -o-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
  }

  .progress-label {
    position: absolute;
    left: 0px;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    padding: 0 12px;
    background: rgba(255, 255, 255, 0.3);
    top: 50%;
    transform: translateY(-50%);
  }

  .conference-image-progress {
    padding: 20px;
  }

  .progress-text {
    display: block;
    color: #222;
    margin-top: 30px;
    font-size: 32px
  }

  .background-button {
    font-size: 36px !important;
    position: fixed;
    top: 10px;
    right: 15px;
    color: #222;
  }
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

@media (min-width: 768px) {
  #conferenceSwitch {
    .conference-switch-item.grid-item {
      width: 25vw;
      height: 25vw;
    }
  }
}

@media (min-width: 1200px) {
  #conferenceSwitch {
    .conference-switch-item.grid-item {
      width: 20vw;
      height: 20vw;
    }
  }
}

#profile-settings {
  &.scroll-content {
    background-color: #FAF8F8;
  }

  .button {
    color: #fff !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .profile-header {
    background: url('assets/bg.jpg') no-repeat bottom center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 250px;
    text-align: center;
  }

  .profile-name {
    padding: 20px 0;
    font-size: 1.4rem;
    color: #fff;

  }

  .profile-avatar {
    padding-top: 20px;

    img {
      background-color: #fff;
      height: 90px;
      width: 90px;
      border-radius: 100%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }

  .profile-content {
    top: -75px;
    position: relative;
    z-index: 20;
    padding: 10px;
  }
}

.content-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  padding: 20px 10px;
  background-color: #fff;
  margin-bottom: 20px;

  h1 {
    font-size: 0.7rem;
    color: #131313;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
  }

  .content-info-item {
    p {
      margin: 0;
      font-size: 0.9rem;
    }

    h2 {
      font-size: 0.7rem;
      color: #131313;
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      border-top: solid 1px #131313;
      margin: 0 0 20px 0;
      opacity: 0.2;
    }

    ul, li {
      font-size: 0.9rem;
    }
  }

  .content-social {
    display: flex;
    width: 100%;

    .content-social-item {
      flex: 1;
      text-align: center;
      font-size: 1.6rem;
      color: #337ab7;
    }
  }
}

#session-detail {
  .item-header-gradient {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background: url(../img/shadow.png) no-repeat bottom center;
    background-size: cover;
  }

  .session-type {
    font-size: 0.9rem;
  }

  .session-title {
    font-weight: 500;
  }

  .session-location {
    padding-top: 10px;
    font-size: 0.9rem;

    span {
      color: inherit;
    }
  }

  .session-outline {
    ul {
      list-style: disc;
      margin-bottom: 20px;
    }

    ul li {
      color: #293039;
      font-size: 15px;
      margin-left: 20px;
      white-space: normal;
    }

    ol {
      list-style: decimal;
      margin-bottom: 20px;
    }

    ol li {
      color: #293039;
      font-size: 15px;
      margin-left: 20px;
      white-space: normal;
    }
  }

  .item.item-tabs {
    padding: 0;
    min-height: 30px;
    color: #222;
    font-weight: 500;
    display: flex;

    .item-tab {
      text-align: center;
      padding: 8px;
      width: 50%;
      cursor: pointer;
      border-bottom: solid 1px #ddd;

      &.active {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }
    }
  }

  .item-divider-title {
    font-weight: 500;
    color: #444;
    font-size: 1rem;
  }

  .item-divider {
    background-color: #fff;
    font-weight: 400;
    padding: 20px 15px 3px 15px;
    font-size: 1.2rem;
  }
}

.item.item-actions {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 8px;
  border-top: 1px solid lightgrey;

  .action-item, a.action-item {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    padding: 8px;
    text-decoration: none;
    //margin: 0 4px;
    width: 25%;
  }

  .action-item-rounded {
    //box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px 0;
    margin: 0 auto;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.success {
      background-color: rgba($positive, 0.75) !important;
    }

    &.danger {
      background-color: rgba($assertive, 0.75) !important;
    }
  }

  .action-item-center {
  }

  i {
    display: block;
    font-size: 1.8rem;
  }

  span {
    font-size: 0.65rem;
    line-height: 1;
    padding-top: 1px;
  }
}

#interactive-admin-wrapper {
  .answer-percentage {
    background-color: #5cb85c;
    padding: 3px 4px 4px 4px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .row, .col {
    padding: 0;
  }

  .label.label-success {
    padding-left: 10px;
    margin-top: -2px;
    margin-left: auto;
  }

  .progress {
    flex: 1;
    max-width: calc(100% - 40px);
  }
}

#qanda-wrapper {
  .button-block {
    margin: 0;
  }
}

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  background-color: #5bc0de;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.flipdiv {
  width: 100%;
  height: 100%;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.question-identifier {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin-right: 4px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
}

.db-loader-wrapper {
  background-color: #222;
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.8;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.db-loader-content {
  opacity: 1;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-top: 50%;
  z-index: 10001;

  .db-loader-text {
    padding: 5px 10px 10px 10px;
    display: block;
    color: lightgrey;
  }

  .db-loader-spinner {
    padding: 10px 0 0 0;
    display: block;
  }
}

$timeslot-height: 180px;
$timeslot-width: 240px;
$roomslot-width: 240px;
$timeheader-width : 80px;
$roomheader-height: 60px;

.sessionGridComponent {
  position: relative;
  width: 100%;
  height: calc(100vh - 160px);
  overflow-x: scroll;
  overflow-y: scroll;

  .session-grid-component {
    display: flex;
    position: relative;
  }

  .corner-item {
    position: fixed;
    display: flex;
    left: 0;
    top:calc(env(safe-area-inset-top) + 99px);
    z-index: 200;
    background-color: #f5f5f5;
    min-width: $timeheader-width;
    width: $timeheader-width;
    height: $roomheader-height;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    justify-content: center;
    align-items: center;

    i {
      position: absolute;
      height: 60px;
      width: 100%;
      margin: 0 auto;
      font-size: 3rem;
      opacity: 0.2;
      line-height: 69px;
      text-align: center;
      z-index: -1;
    }

    .corner-item-text {
      font-size: 0.7rem;
      font-weight: 700;
    }
  }

 

  .consultant-item-iphone {
    top: 43px !important;
  }

  .room-items {
    > .room-item:first-child {
      min-width: $timeheader-width;
      width: $timeheader-width;
      border-top: solid 2px rgba(0, 0, 0, 0.125);
      border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    }
  }

  .room-item {
    background-color: #f5f5f5;
    min-width: $roomslot-width;
    width: $timeslot-width;
    height: $roomheader-height;
    display: flex;
    padding: 0.25rem 0.5rem;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 700;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    a{
      text-decoration:none;
    }
  }

  .hours-grid {
    font-size: 0.7rem;
    font-weight: 700;
  }

  .hour-items-row {
    display: flex;
    position: relative;
    height: $timeslot-height;
  }

  .hour-header-item {
    width: $timeheader-width;
    min-width: $timeheader-width;
    height: $timeslot-height;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    font-size: 0.9rem;
    font-weight: 700;
  }

  .hour-item {
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    width: $timeslot-width;
    min-width: $timeslot-width;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.12), 0 0 0 rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .session-item {
    position: absolute;
    height: $timeslot-height;
    top: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-decoration:none;
    justify-content: space-between;

    .break-title {
      font-size: 0.9rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      min-width: 0;

      &.rotated {
        width: auto;
        min-width: auto;
        transform: rotate(90deg);
      }
    }

    &.is-break {
      top: $roomheader-height;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
    }
.si-content {
  border: 1px solid #EBECEE;
  border-left: 8px solid #EBECEE;
  border-radius: 4px;
  background:#fff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  //padding: 10px;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

}
    .si-code {
      font-size: 0.8rem;
      background: #858585;
      font-weight: 600;
      padding: 3px 2px;
      line-height: 1;
      border-radius: 2px;
      color: #fff;
      width: max-content;
      margin-bottom: 5px;
    }

    .si-time {
      position:absolute;
      top:8px;
      right:8px;
      font-size: 0.8rem;
      color: #858585;
      font-weight: 600;
      margin: 0;
      line-height: 1.1;
    }

    .si-title {
      color: #858585;
      padding-left:5px;
      font-weight: 600;
      margin: 0;
      line-height: 1.1;
      font-size: 0.9rem;
    }

    .filler {
      background-color: #f5f5f5;
      flex: 1;
      height: $timeslot-height;
      border-top: solid 2px rgba(0, 0, 0, 0.125);
      border-bottom: solid 2px rgba(0, 0, 0, 0.125);
    }
  }
}

.sessionGridComponent.rooms-vertical {
  .hours-grid {
    > .hour-items-row:first-child {
      display: flex;
      position: sticky;
      position:-webkit-sticky;
      top: 0;
      align-self: flex-start;
      height: auto;
      z-index: 100;
    }
  }

  .room-items {
    position: sticky;
    position:-webkit-sticky;
    left: 0;
    align-self: flex-start;
    height: auto;
    z-index: 100;
  }
}

.sessionGridComponent.rooms-horizontal {
  .session-grid-component {
    flex-direction: column;
  }

  .room-items {
    display: flex;
    position: sticky;
    position:-webkit-sticky;
    top: 0;
    align-self: flex-start;
    height: auto;
    z-index: 100;
  }

  .room-item {
    border-top: solid 2px rgba(0, 0, 0, 0.125);
    border-bottom: solid 2px rgba(0, 0, 0, 0.125);
  }

  .hour-items-row {
    height: auto;
    flex-direction: column;
  }

  .hours-grid {
    display: flex;

    > .hour-items-row:first-child {
      display: flex;
      position: sticky;
      position:-webkit-sticky;
      left: 0;
      align-self: flex-start;
      height: auto;
      z-index: 100;
    }
  }

  .hour-header-item {
    border-right: solid 1px rgba(0, 0, 0, 0.125);
    border-bottom: solid 1px rgba(0, 0, 0, 0.125);
    border-top: solid 0px rgba(0, 0, 0, 0.125);
  }

  .hour-item {
    height: $timeslot-height;
    min-height: $timeslot-height;
  }

  .session-item {
   
    width: $timeslot-width;
    &.is-break {
      margin-left: $timeheader-width;
    }

    .break-title {
      transform: rotate(0deg);

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }

}
